const keys = {
    token: "token",
    rememberEmail: "rememberEmail",
    rememberPassword: "rememberPassword",
}

function set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function get(key) {
    let value = localStorage.getItem(key);
    if (value === "undefined") {
        value = null;
    }
    if (value === "null") {
        value = null;
    }
    return JSON.parse(value)
}

function remove(key) {
    localStorage.removeItem(key);
}

function clearAll() {
    localStorage.clear();
}

const LocalStorage = {
    set,
    get,
    remove,
    clearAll,
    keys,
}

export default LocalStorage;
