import {
    TOAST_SHOW_INFO,
    TOAST_SHOW_SUCCESSFUL,
    TOAST_SHOW_WARNING,
    TOAST_SHOW_ERROR,
    TOAST_SHOW_DEFAULT,
    TOAST_CLOSE
} from '../actions/toastActions';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import InitialValues from "../utils/InitialValues";
import Settings from '../utils/Settings';

export default (previousState = InitialValues.getToast(), action) => {
    if (Settings.TOAST_USE_WITH_TOASTIFY) {
        const option = {
            ...previousState.options
        };

        if (action.type === TOAST_SHOW_INFO)
            toast.info(action.payload.message, option);
        else if (action.type === TOAST_SHOW_SUCCESSFUL)
            toast.success(action.payload.message, option);
        else if (action.type === TOAST_SHOW_WARNING)
            toast.warn(action.payload.message, option);
        else if (action.type === TOAST_SHOW_ERROR)
            toast.error(action.payload.message, option);
        else if (action.type === TOAST_SHOW_DEFAULT)
            toast(action.payload.message, option);
    }

    switch (action.type) {
        case TOAST_CLOSE:
            return {
                ...previousState,
                type: action.payload.type,
                isOpen: action.payload.isOpen
            };
        case TOAST_SHOW_INFO:
            return {
                ...previousState,
                message: action.payload.message,
                type: action.type,
                isOpen: action.payload.isOpen
            };
        case TOAST_SHOW_SUCCESSFUL:
            return {
                ...previousState,
                message: action.payload.message,
                type: action.type,
                isOpen: action.payload.isOpen
            };
        case TOAST_SHOW_WARNING:
            return {
                ...previousState,
                message: action.payload.message,
                type: action.type,
                isOpen: action.payload.isOpen
            };
        case TOAST_SHOW_ERROR:
            return {
                ...previousState,
                message: action.payload.message,
                type: action.type,
                isOpen: action.payload.isOpen
            };
        case TOAST_SHOW_DEFAULT:
            return {
                ...previousState,
                message: action.payload.message,
                type: action.type,
                isOpen: action.payload.isOpen
            };
        default:
            return previousState;
    }
}
