import React, {Component} from 'react';
import {connect} from 'react-redux';
import {dialog, SHOW_DIALOG_ADD_NOTE} from '../actions/dialogActions';
import InitialValues from "../utils/InitialValues";
import {tempNoteChange} from "../actions/noteActions";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from "@material-ui/styles";
import {fade} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SharedComponentUtils from "../utils/SharedComponentUtils";
import {withRouter} from "react-router-dom";
import Functions from "../utils/Functions";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Box from '@material-ui/core/Box';

//Icons
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Constants from "../utils/Constants";
import {sessionStorageNotify} from "../actions/sessionStorageActions";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
});

class Header extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            searchText: "",
            anchorEl: null,
        }
    }

    onDrawerMenuClick_Navigate(url, event) {
        event.preventDefault();

        this.props.history.push(url);
    };

    checkAdmin() {
        return SharedComponentUtils.checkRole(this, Constants.Roles.Admin);
    }

    render() {
        const onClickButton_New = () => {
            this.props.tempNoteChange(InitialValues.getTempNote());
            this.props.dialog(SHOW_DIALOG_ADD_NOTE);
        };

        const onChangeInputBase_SearchText = (event) => {
            const value = event.target.value;
            this.setState({
                searchText: value
            })
        };

        const onKeyUpCaptureInputBase_SearchText = (event) => {
            if (event.key === 'Enter') {
                console.log("Searching...", event);

                this.setState({
                    searchText: ""
                });
            }
            else if (event.key === 'Escape') {
                console.log("Cancel...", event);

                this.setState({
                    searchText: ""
                });
            }
            else {
                console.log("Search Key: " + event.key, event);
            }
        };

        const onClickIconButton_MenuOpen = (event) => {
            this.setState({
                anchorEl: event.currentTarget,
            })
        };

        const onClickMenu_MyProfile = () => {
            this.props.history.push("/myProfile");
            onMenu_HandleClose();
        };

        const onClickMenu_SignOut = () => {
            SharedComponentUtils.signOut(this);
            onMenu_HandleClose();
        };

        const onMenu_HandleClose = () => {
            this.setState({
                anchorEl: null
            })
        };

        const list = () => (
            <div
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <Box style={{display: this.checkAdmin() ? "inherit" : "none"}}>
                    <List>
                        <ListItem button>
                            <ListItemText secondary="Admin Settings"/>
                        </ListItem>
                        <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/userRoles")}>
                            <ListItemIcon>
                                <VerifiedUserIcon/>
                            </ListItemIcon>
                            <ListItemText primary="User Roles"/>
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
                <List>
                    <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/")}>
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboard"/>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/userSettings")}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                    </ListItem>
                    <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/technologies")}>
                        <ListItemIcon>
                            <OfflineBoltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Technologies" />
                    </ListItem>
                    <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/about")}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="About" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={this.onDrawerMenuClick_Navigate.bind(this, "/buyMeACoffee")}>
                        <ListItemIcon>
                            <FastfoodIcon />
                        </ListItemIcon>
                        <ListItemText primary="Buy me a coffee" />
                    </ListItem>
                </List>
            </div>
        );

        const toggleDrawer = (isOpen) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            this.setState({
                isDrawerOpen: isOpen
            });
        };

        return (
            <>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={this.props.classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Drawer anchor={"left"} open={this.state.isDrawerOpen} onClose={toggleDrawer(false)}>
                            {list()}
                        </Drawer>
                        <Typography className={this.props.classes.title} variant="h6" noWrap>
                            Realtime Notepad
                        </Typography>
                        <div className={this.props.classes.search}>
                            <div className={this.props.classes.searchIcon}>
                                <SearchIcon/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: this.props.classes.inputRoot,
                                    input: this.props.classes.inputInput,
                                }}
                                inputProps={{'aria-label': 'search'}}
                                onKeyUpCapture={onKeyUpCaptureInputBase_SearchText}
                                onChange={onChangeInputBase_SearchText}
                                value={this.state.searchText}
                            />
                        </div>
                        <Fab color="secondary"
                             aria-label="add"
                             size="small"
                             onClick={onClickButton_New}
                             aria-controls="menu-appbar"
                             aria-haspopup="true"
                             style={{marginLeft: 16}}
                        >
                            <AddIcon/>
                        </Fab>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={onClickIconButton_MenuOpen}
                        >
                            <AccountCircle/>
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={onMenu_HandleClose}
                        >
                            <MenuItem>
                                <Typography color={"textSecondary"}>
                                    {Functions.isNull(this.props.sessionStorageReducer.data) ? "" : this.props.sessionStorageReducer.data.userInfo.nameSurname}
                                </Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={onClickMenu_MyProfile}>My Profile</MenuItem>
                            <MenuItem onClick={onClickMenu_SignOut}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    dialog,
    tempNoteChange,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Header)));
