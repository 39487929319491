export const LOADING_SHOW = "LOADING_SHOW";

export function loadingShow(isShow = false) {
    return {
        type: LOADING_SHOW,
        payload: {
            isShow: isShow
        }
    }
}
