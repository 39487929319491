import Functions from "./Functions";

const keys = {
    userSettings: "userSettings",
    isSignInCheckFromServer: "isSignInCheckFromServer",
    userInfo: "userInfo",
    noteList: "noteList",
}

const defaultData = {
    [keys.userSettings]: {
        isDarkMode: false,
        noteRecordCountPerPage: 16,
    },
    [keys.isSignInCheckFromServer]: false,
    [keys.userInfo]: {
        userId: "",
        email: "",
        isEmailConfirm: false,
        nameSurname: "",
        roles: [
            {role: ""}
        ],
    },
    [keys.noteList]: {
        skip: 0,
        length: 0,
        total: 0,
        noteList: [],
    },
};

let data = defaultData;
init();

function init() {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        const value = get(key);
        data = {
            ...data,
            [key]: value,
        }
    }
}

function set(key, value, _this = null) {
    data = {
        ...data,
        [key]: value
    }
    sessionStorage.setItem(key, JSON.stringify(value));

    if (!Functions.isNull(_this)) {
        _this.props.sessionStorageNotify();
    }
}

function get(key, defaultValue = null) {
    let value = sessionStorage.getItem(key);
    let result = null;
    if (value === undefined || value === null) {
        result = defaultValue;
    }
    else {
        result = JSON.parse(value);
    }

    return result;
}

function remove(key, _this = null) {
    set(key, null, _this);
    sessionStorage.removeItem(key);
}

function clearAll(_this = null) {
    data = defaultData;
    sessionStorage.clear();
    if (!Functions.isNull(_this)) {
        _this.props.sessionStorageNotify();
    }
}

function getData() {
    return Functions.DeepCopy(data);
}

const SessionStorage = {
    set,
    get,
    remove,
    clearAll,
    keys,
    getData,
}

export default SessionStorage;
