import React, {Component} from 'react';
import {connect} from 'react-redux';
import {modal, CLOSE_MODAL, SHOW_MODAL_ADD_NOTE} from "../../../actions/modalActions";
import {toastShow, TOAST_SHOW_SUCCESSFUL} from "../../../actions/toastActions";
import {loadingShow} from "../../../actions/loadingActions";
import CustomModal from "./CustomModal";
import NoteForm from "../../NoteForm";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

class AddNoteModal extends Component {
    ModalContent() {
        const title = "Add Note";
        return {
            isShow: (this.props.modalReducer.type === SHOW_MODAL_ADD_NOTE),
            modalTitle: title,
            isShowCloseButton: true,
            modalContent: (
                <>
                    <Box>
                        <NoteForm/>
                        <Box mt={2}>
                            <Divider/>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Box ml={1} mt={2}>
                                <Button variant="contained" color="primary" onClick={this.onClickButton_AddNote.bind(this)}>{title}</Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }
    }

    onClickButton_AddNote(event) {
        this.props.loadingShow(true);
        this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Add note successful...");
        this.props.modal(CLOSE_MODAL);
        this.props.loadingShow(false);

        event.preventDefault();
    }

    render() {
        return (
            <>
                <CustomModal content={this.ModalContent()} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    modal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNoteModal);
