import React, { Component } from 'react';
import {connect} from "react-redux";
import {loadingShow} from "../../../actions/loadingActions";
import Box from '@material-ui/core/Box';
import Header from '../../Header';
import Footer from '../../Footer';
import SharedComponentUtils from "../../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";
import Functions from "../../../utils/Functions";
import Restful from "../../../utils/Restful";
import {TOAST_SHOW_ERROR, TOAST_SHOW_SUCCESSFUL, TOAST_SHOW_WARNING, toastShow} from "../../../actions/toastActions";
import SessionStorage from "../../../utils/SessionStorage";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {withStyles} from "@material-ui/styles";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class MyProfile extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            nameSurname: SessionStorage.get(SessionStorage.keys.userInfo, {
                nameSurname: ""
            }).nameSurname,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            isLoading: false,
            isErrorNameSurname: false,
            isErrorOldPassword: false,
            isErrorNewPassword: false,
            isErrorConfirmPassword: false,
            isDisableChangeProfileButton: true,
            isDisableChangePasswordButton: true,
        }
    }

    componentDidMount() {
        SharedComponentUtils.loginCheck(this);
    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.name === "confirmPassword") {
            if (this.state.newPassword !== event.target.value) {
                this.setState({
                    isErrorConfirmPassword: true,
                    isDisableChangePasswordButton: true
                });
            }
            else {
                this.setState({
                    isErrorConfirmPassword: false,
                    isDisableChangePasswordButton: false
                });
            }
        }

        if (event.target.name === "nameSurname") {
            if (Functions.isNullOrEmpty(event.target.value)) {
                this.setState({
                    isErrorNameSurname: true,
                    isDisableChangeProfileButton: true
                });
            }
            else {
                this.setState({
                    isErrorNameSurname: false,
                    isDisableChangeProfileButton: false
                });
            }
        }
    }

    formValidateProfileChange() {
        let result = true;

        this.setState({
            isErrorNameSurname: Functions.isNullOrEmpty(this.state.nameSurname),
        });

        if (Functions.isNullOrEmpty(this.state.nameSurname)) {
            result = false;
        }

        return result;
    }

    formValidatePasswordChange() {
        let result = true;

        this.setState({
            isErrorOldPassword: Functions.isNullOrEmpty(this.state.oldPassword),
            isErrorNewPassword: Functions.isNullOrEmpty(this.state.newPassword),
            isErrorConfirmPassword: Functions.isNullOrEmpty(this.state.confirmPassword),
        });

        if (Functions.isNullOrEmpty(this.state.oldPassword)
            || Functions.isNullOrEmpty(this.state.newPassword)
            || Functions.isNullOrEmpty(this.state.confirmPassword)
        ) {
            result = false;
        }

        return result;
    }

    onClickProfileChange(event) {
        event.preventDefault();

        if (!this.formValidateProfileChange()) {
            return;
        }

        Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_EDIT_MYPROFILE, {
            nameSurname: this.state.nameSurname,
        }, this, (response) => {
            const data = response.data.data.editMyProfile;

            const userInfo = {
                userId: data.userId,
                nameSurname: data.nameSurname,
                email: data.email,
                isEmailConfirm: data.isEmailConfirm,
            }

            SessionStorage.set(SessionStorage.keys.userInfo, userInfo, this);

            if (data.isEmailConfirm) {
                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Change profile successful!");
            }
            else {
                this.props.toastShow(TOAST_SHOW_WARNING, "Email is not activated! Please activate your email address.");
            }

            this.setState({
                isErrorNameSurname: false,
            });
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
                isDisableChangeProfileButton: isLoading,
            });
        });
    }

    onClickPasswordChange(event) {
        event.preventDefault();

        if (!this.formValidatePasswordChange()) {
            return;
        }

        Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_CHANGE_PASSWORD, {
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
        }, this, (response) => {
            //const data = response.data.data.changePassword;

            this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Change password successful!");

            this.setState({
                isErrorOldPassword: false,
                isErrorNewPassword: false,
                isErrorConfirmPassword: false,
            });
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
                isDisableChangePasswordButton: isLoading,
            });
        });
    }

    render() {
        return (
            <>
                <Header/>
                <Box mt={6}>
                    <Container component="main" maxWidth="xs">
                        <div className={this.props.classes.paper}>
                            <Avatar className={this.props.classes.avatar}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                My Profile
                            </Typography>
                            <form className={this.props.classes.form} noValidate onSubmit={this.onClickProfileChange.bind(this)}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="nameSurname"
                                    label="Name Surname"
                                    name="nameSurname"
                                    autoComplete="nameSurname"
                                    value={this.state.nameSurname}
                                    error={this.state.isErrorNameSurname}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={Functions.isNull(this.props.sessionStorageReducer.data) ? "" : this.props.sessionStorageReducer.data.userInfo.email}
                                    disabled={true}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.isDisableChangeProfileButton}
                                    className={this.props.classes.submit}
                                >
                                    Change Profile
                                </Button>
                            </form>
                            <form className={this.props.classes.form} noValidate onSubmit={this.onClickPasswordChange.bind(this)}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="oldPassword"
                                    label="Old Password"
                                    type="password"
                                    id="oldPassword"
                                    autoComplete="password"
                                    value={this.state.oldPassword}
                                    error={this.state.isErrorOldPassword}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    id="newPassword"
                                    autoComplete="password"
                                    value={this.state.newPassword}
                                    error={this.state.isErrorNewPassword}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="confirm-password"
                                    value={this.state.confirmPassword}
                                    error={this.state.isErrorConfirmPassword}
                                    onChange={this.onChange.bind(this)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.isDisableChangePasswordButton}
                                    className={this.props.classes.submit}
                                >
                                    Change Password
                                </Button>
                            </form>
                        </div>
                    </Container>
                </Box>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(MyProfile)));
