import React, { Component } from 'react';
import {connect} from "react-redux";
import {loadingShow} from "../../actions/loadingActions";
import Box from '@material-ui/core/Box';
import Header from '../Header';
import Footer from '../Footer';
import SharedComponentUtils from "../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../actions/sessionStorageActions";
import {withStyles} from "@material-ui/styles";
import Restful from "../../utils/Restful";
import {
    TOAST_SHOW_ERROR,
    TOAST_SHOW_SUCCESSFUL,
    toastShow,
} from "../../actions/toastActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SessionStorage from "../../utils/SessionStorage";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    paperControl: {
        margin: theme.spacing(3),
        padding: theme.spacing(1),
    },
});

class UserSettings extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            users: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        SharedComponentUtils.loginCheck(this);
    }

    onChange(changeOnServer= true, event) {
        const key = event.target.name;
        let value = event.target.value;

        if (key === "isDarkMode")  {
            value = event.target.checked
        }
        if (key === "noteRecordCountPerPage")  {
            value = parseInt(event.target.value);
        }

        if (changeOnServer) {
            Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_CHANGE_USER_SETTINGS, {
                key: key,
                value: JSON.stringify(value),
            }, this, (response) => {
                const data = response.data.data.changeUserSetting;

                let tempUserSettings = SessionStorage.getData().userSettings;
                data.forEach(function (item, index, array) {
                    tempUserSettings = {
                        ...tempUserSettings,
                        [item.key]: JSON.parse(item.value),
                    }
                });
                SessionStorage.set(SessionStorage.keys.userSettings, tempUserSettings, this);

                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Changed user settings!");
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                });
            });
        }
        else {
            let tempUserSettings = SessionStorage.getData().userSettings;
            tempUserSettings = {
                ...tempUserSettings,
                [key]: JSON.parse(value),
            }
            SessionStorage.set(SessionStorage.keys.userSettings, tempUserSettings, this);
        }
    }

    render() {
        return (
            <>
                <Header/>
                <Box mt={6}>
                    <Paper className={this.props.classes.paperControl}>
                        <Typography variant="h6" noWrap>Styles</Typography>
                        <Divider />
                        <br/>
                        <FormControlLabel
                            control={<Checkbox checked={this.props.sessionStorageReducer.data.userSettings.isDarkMode}
                                               onChange={this.onChange.bind(this, true)} name="isDarkMode"/>}
                            label="Is Dark Mode"
                        />
                    </Paper>

                    <Paper className={this.props.classes.paperControl}>
                        <Typography variant="h6" noWrap>Note Settings</Typography>
                        <Divider />
                        <br/>
                        <form noValidate autoComplete="off">
                            <TextField id="standard-basic" label="Note Record Show Count Per Page"
                                       name="noteRecordCountPerPage"
                                       value={this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage}
                                       onChange={this.onChange.bind(this, false)}
                                       onBlur={this.onChange.bind(this, true)}/>
                        </form>
                    </Paper>
                </Box>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserSettings));
