import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toastClose,
    TOAST_SHOW_DEFAULT,
    TOAST_SHOW_ERROR,
    TOAST_SHOW_INFO,
    TOAST_SHOW_SUCCESSFUL,
    TOAST_SHOW_WARNING
} from "../../actions/toastActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import Functions from '../../utils/Functions';
import Settings from '../../utils/Settings';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PropTypeToToastType(type) {
    if (Functions.isNullOrEmpty(type))
        return null;

    if (type === TOAST_SHOW_ERROR)
        return "error";
    else if (type === TOAST_SHOW_INFO)
        return "info";
    else if (type === TOAST_SHOW_SUCCESSFUL)
        return "success";
    else if (type === TOAST_SHOW_WARNING)
        return "warning";
}

function DefaultSnackbar(props, onClickButton_ToastClose) {
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={props.toastReducer.isOpen}
                autoHideDuration={4000}
                onClose={onClickButton_ToastClose}
                message={props.toastReducer.message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={onClickButton_ToastClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={props.toastReducer.type}
            />
        </>
    );
}

function StateSnackbar(props, onClickButton_ToastClose) {
    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={props.toastReducer.isOpen}
                autoHideDuration={4000}
                onClose={onClickButton_ToastClose}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={onClickButton_ToastClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={props.toastReducer.type}>
                <Alert onClose={onClickButton_ToastClose} severity={PropTypeToToastType(props.toastReducer.type)}>
                    {props.toastReducer.message}
                </Alert>
            </Snackbar>
        </>
    );
}

function getSnackbar(props, onClickButton_ToastClose) {
    if (props === null) {
        return null;
    }

    if (!Settings.TOAST_USE_WITH_TOASTIFY) {
        return props.toastReducer.type === TOAST_SHOW_DEFAULT ? DefaultSnackbar(props, onClickButton_ToastClose) : StateSnackbar(props, onClickButton_ToastClose);
    }

    return null;
}

class Toast extends Component {
    render() {
        const onClickButton_ToastClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            this.props.toastClose();
        };

        return (
            <>
                {
                    getSnackbar(this.props, onClickButton_ToastClose)
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    toastClose
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
