import {
    CLOSE_MODAL,
    SHOW_MODAL_ADD_NOTE,
    SHOW_MODAL_EDIT_NOTE
} from "../actions/modalActions";
import InitialValues from "../utils/InitialValues";

export default (previousState = InitialValues.getModal(), action) => {
    if (
        action.type === CLOSE_MODAL
        || action.type === SHOW_MODAL_ADD_NOTE
        || action.type === SHOW_MODAL_EDIT_NOTE
    ) {
        return {
            ...previousState,
            type: action.type
        };
    } else {
        return previousState;
    }
}
