import React, {Component} from 'react';
import {connect} from 'react-redux';
import './App.css';
import ExternalComponents from './components/externalComponents/index'
import 'fontsource-roboto';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
} from "react-router-dom";
import Routes from './routes/index';
import {loadingShow} from "./actions/loadingActions";
import {createMuiTheme, CssBaseline} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

class App extends Component {
    getTheme(isDarkMode) {
        const theme = createMuiTheme({
            palette: {
                type: isDarkMode ? 'dark' : 'light'
            }
        });

        return theme;
    }

    render() {
        return (
            <>
                <ThemeProvider theme={this.getTheme(this.props.sessionStorageReducer.data.userSettings.isDarkMode)}>
                    <CssBaseline/>
                    <div className="App">
                        <Router>
                            <Switch>
                                {Routes.RoutesWithProtect.map(route => <Route key={route.name} {...route} />)}
                                <Redirect to="/signin" />
                            </Switch>
                        </Router>

                        <ExternalComponents/>
                    </div>
                </ThemeProvider>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
