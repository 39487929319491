import React, {Component} from 'react';
import Loading from "./Loading";
import Toast from "./Toast";
import AddNoteDialog from "./dialogs/AddNoteDialog";
import EditDeleteNoteDialog from "./dialogs/EditDeleteNoteDialog";
import AddNoteModal from "./modals/AddNoteModal";
import EditDeleteNoteModal from "./modals/EditDeleteNoteModal";
import {ToastContainer} from "react-toastify";

class Index extends Component {
    render() {
        return (
            <>
                <Loading/>
                <Toast/>
                <ToastContainer/>
                <AddNoteDialog/>
                <EditDeleteNoteDialog/>
                <AddNoteModal/>
                <EditDeleteNoteModal/>
            </>
        );
    }
}

export default Index;
