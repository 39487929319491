import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Restful from '../../../utils/Restful';
import {loadingShow} from "../../../actions/loadingActions";
import {TOAST_SHOW_ERROR, TOAST_SHOW_SUCCESSFUL, TOAST_SHOW_WARNING, toastShow} from "../../../actions/toastActions";
import Functions from "../../../utils/Functions";
import EmailValidator from "email-validator";
import SharedComponentUtils from "../../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";
import Box from '@material-ui/core/Box';
import LocalStorage from "../../../utils/LocalStorage";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignIn extends Component {
    constructor(props, context) {
        super(props, context);

        const rememberEmail = LocalStorage.get(LocalStorage.keys.rememberEmail);
        const rememberPassword = LocalStorage.get(LocalStorage.keys.rememberPassword);
        const remember = !Functions.isNullOrEmpty(rememberEmail) && !Functions.isNullOrEmpty(rememberPassword);

        this.state = {
            email: rememberEmail === null ? '' : rememberEmail,
            password: rememberPassword === null ? '' : rememberPassword,
            isLoading: false,
            isErrorEmail: false,
            isErrorPassword: false,
            isDisableSubmitButton: false,
            remember: remember,
        }
    }

    componentDidMount() {
        this.emailActivationCheck();
    }

    onChange(event) {
        if (event.target.name === "remember") {
            this.setState({
                [event.target.name]: event.target.checked,
            });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    }

    formValidate() {
        let result = true;

        this.setState({
            isErrorEmail: Functions.isNullOrEmpty(this.state.email)
        });
        this.setState({
            isErrorPassword: Functions.isNullOrEmpty(this.state.password)
        });

        if (Functions.isNullOrEmpty(this.state.email)
            || Functions.isNullOrEmpty(this.state.password)
        ) {
            result = false;
        }

        if (!EmailValidator.validate(this.state.email)) {
            this.setState({
                isErrorEmail: true
            });
            result = false;
        }

        return result;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.formValidate()) {
            return;
        }

        this.restfulSignIn(this.state.email, this.state.password)
    }

    onClickSignInWithDemoUser(event) {
        event.preventDefault();

        this.restfulSignIn("demo@realtimenotepad.com", "demo");
    }

    restfulSignIn(email, password) {
        Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_SIGNIN, {
            email: email,
            password: password,
            noteSkip: 0,
            noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
        }, this, (response) => {
            const data = response.data.data.signIn;

            this.startSignInProcesses(data);
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }

            this.setState({
                isError: true
            });
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
                isDisableSubmitButton: isLoading,
            });
        });
    }

    startSignInProcesses(data) {
        SharedComponentUtils.signInResponseHandler(data, this);

        if (this.state.remember) {
            LocalStorage.set(LocalStorage.keys.rememberEmail, this.state.email);
            LocalStorage.set(LocalStorage.keys.rememberPassword, this.state.password);
        }
        else {
            LocalStorage.clearAll();
        }

        if (data.user.isEmailConfirm) {
            this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "SignIn successful!");
        }
        else {
            this.props.toastShow(TOAST_SHOW_WARNING, "Email is not activated! Please activate your email address.");
        }

        this.setState({
            isError: false,
            isErrorPassword: false,
        });

        this.props.history.push("/");
    }

    onClickSignUp(event) {
        event.preventDefault();
        this.props.history.push("/signup");
    }

    onClickForgotPassword(event) {
        event.preventDefault();
        this.props.history.push("/forgotPassword");
    }

    emailActivationCheck() {
        const emailActivationCode = new URLSearchParams(this.props.location.search).get("emailactivationcode");
        if (!Functions.isNullOrEmpty(emailActivationCode)) {
            Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_EMAIL_ACTIVATION, {
                emailActivationCode: emailActivationCode,
                noteSkip: 0,
                noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage
            }, this, (response) => {
                const data = response.data.data.emailActivation;

                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "E-Mail activated!");

                this.startSignInProcesses(data);
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }

                this.setState({
                    isError: true
                });
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                    isDisableSubmitButton: isLoading,
                });
            });
        }
    }

    render() {
        return (
            <>
                <Container component="main" maxWidth="xs">
                <div className={this.props.classes.paper}>
                    <Avatar className={this.props.classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={this.props.classes.form} noValidate onSubmit={this.onSubmit.bind(this)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={this.state.email}
                            error={this.state.isErrorEmail}
                            onChange={this.onChange.bind(this)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            error={this.state.isErrorPassword}
                            onChange={this.onChange.bind(this)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" checked={this.state.remember} name="remember" color="primary" onChange={this.onChange.bind(this)} />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={this.props.classes.submit}
                            disabled={this.state.isDisableSubmitButton}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" onClick={this.onClickForgotPassword.bind(this)}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2" onClick={this.onClickSignUp.bind(this)}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent="flex-end">
                                    <Link href="#" variant="body2" onClick={this.onClickSignInWithDemoUser.bind(this)}>
                                        {"Sign in with Demo account"}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));
