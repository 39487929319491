import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {loadingShow} from "../../../actions/loadingActions";
import {TOAST_SHOW_ERROR, TOAST_SHOW_SUCCESSFUL, TOAST_SHOW_WARNING, toastShow} from "../../../actions/toastActions";
import Restful from "../../../utils/Restful";
import Functions from "../../../utils/Functions";
import EmailValidator from "email-validator";
import { withRouter } from "react-router-dom";
import SharedComponentUtils from "../../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class SignUp extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            nameSurname: '',
            email: '',
            password: '',
            confirmPassword: '',
            isLoading: false,
            isErrorNameSurname: false,
            isErrorEmail: false,
            isErrorPassword: false,
            isErrorConfirmPassword: false,
            isDisableSubmitButton: true
        }
    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.name === "confirmPassword") {
            if (this.state.password !== event.target.value) {
                this.setState({
                    isErrorConfirmPassword: true,
                    isDisableSubmitButton: true
                });
            }
            else {
                this.setState({
                    isErrorConfirmPassword: false,
                    isDisableSubmitButton: false
                });
            }
        }
    }

    formValidate() {
        let result = true;

        this.setState({
            isErrorNameSurname: Functions.isNullOrEmpty(this.state.nameSurname),
            isErrorEmail: Functions.isNullOrEmpty(this.state.email),
            isErrorPassword: Functions.isNullOrEmpty(this.state.password),
            isErrorConfirmPassword: Functions.isNullOrEmpty(this.state.confirmPassword),
        });

        if (Functions.isNullOrEmpty(this.state.nameSurname)
            || Functions.isNullOrEmpty(this.state.email)
            || Functions.isNullOrEmpty(this.state.password)
            || Functions.isNullOrEmpty(this.state.confirmPassword)
        ) {
            result = false;
        }

        if (!EmailValidator.validate(this.state.email)) {
            this.setState({
                isErrorEmail: true
            });
            result = false;
        }

        return result;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.formValidate()) {
            return;
        }

        Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_SIGNUP, {
            nameSurname: this.state.nameSurname,
            email: this.state.email,
            password: this.state.password,
            noteSkip: 0,
            noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
        }, this, (response) => {
            const data = response.data.data.signUp;

            SharedComponentUtils.signInResponseHandler(data, this);

            if (data.user.isEmailConfirm) {
                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "SignUp successful!");
            }
            else {
                this.props.toastShow(TOAST_SHOW_WARNING, "Email is not activated! Please activate your email address.");
            }

            this.setState({
                isErrorPassword: false,
                isErrorConfirmPassword: false,
            });

            this.props.history.push("/");
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
                isDisableSubmitButton: isLoading,
            });
        });
    }

    render() {
        return (
            <>
                <Container component="main" maxWidth="xs">
                    <div className={this.props.classes.paper}>
                        <Avatar className={this.props.classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <form className={this.props.classes.form} noValidate onSubmit={this.onSubmit.bind(this)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="nameSurname"
                                label="Name Surname"
                                name="nameSurname"
                                autoComplete="nameSurname"
                                value={this.state.nameSurname}
                                error={this.state.isErrorNameSurname}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={this.state.email}
                                error={this.state.isErrorEmail}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="password"
                                value={this.state.password}
                                error={this.state.isErrorPassword}
                                onChange={this.onChange.bind(this)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="confirm-password"
                                value={this.state.confirmPassword}
                                error={this.state.isErrorConfirmPassword}
                                onChange={this.onChange.bind(this)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={this.state.isDisableSubmitButton}
                                className={this.props.classes.submit}
                            >
                                Sign Up
                            </Button>
                        </form>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SignUp)));
