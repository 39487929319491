export const MUTATION_SIGNUP = `
mutation($nameSurname: String!, $email: String!, $password: String!, $noteSkip: Int, $noteLimit: Int!) {
  signUp(
    data: { nameSurname: $nameSurname, email: $email, password: $password }
  ) {
    token
    roles {
      role
    }
    user {
      userId
      nameSurname
      email
      isEmailConfirm
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedNotes {
            sharedNoteId
            toUserId
            created
            user {
              nameSurname
              email
            }
          }
        }
      }
    }
    userSettings {
      key
      value
    }
  }
}
`;

export const MUTATION_SIGNIN = `
mutation($email: String!, $password: String!, $noteSkip: Int, $noteLimit: Int!) {
  signIn(
    data: { email: $email, password: $password }
  ) {
    token
    roles {
      role
    }
    user {
      userId
      nameSurname
      email
      isEmailConfirm
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedNotes {
            sharedNoteId
            toUserId
            created
            user {
              nameSurname
              email
            }
          }
        }
      }
    }
    userSettings {
      key
      value
    }
  }
}
`;

export const MUTATION_SIGNIN_VERIFY = `
mutation($noteSkip: Int, $noteLimit: Int!) {
  signInVerify {
    token
    roles {
      role
    }
    user {
      userId
      nameSurname
      email
      isEmailConfirm
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedNotes {
            sharedNoteId
            toUserId
            created
            user {
              nameSurname
              email
            }
          }
        }
      }
    }
    userSettings {
      key
      value
    }
  }
}
`;

export const MUTATION_EDIT_MYPROFILE = `
mutation($nameSurname: String!) {
  editMyProfile(data: { nameSurname: $nameSurname }) {
    userId
    nameSurname
    email
    isEmailConfirm
  }
}
`;

export const MUTATION_CHANGE_PASSWORD = `
mutation($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    userId
  }
}
`;

export const MUTATION_EMAIL_ACTIVATION = `
mutation($emailActivationCode: String!, $noteSkip: Int, $noteLimit: Int!) {
  emailActivation(emailActivationCode: $emailActivationCode) {
    token
    roles {
      role
    }
    user {
      userId
      nameSurname
      email
      isEmailConfirm
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedNotes {
            sharedNoteId
            toUserId
            created
            user {
              nameSurname
              email
            }
          }
        }
      }
    }
    userSettings {
      key
      value
    }
  }
}
`;

export const MUTATION_FORGOT_PASSWORD = `
mutation($email: String!) {
  forgotPassword(email: $email)
}
`;

export const MUTATION_FORGOT_PASSWORD_CONFIRM = `
mutation($forgotPasswordCode: String!, $email: String!, $newPassword: String!, $noteSkip: Int, $noteLimit: Int!) {
  forgotPasswordConfirm(forgotPasswordCode: $forgotPasswordCode, email: $email, newPassword: $newPassword) {
    token
    roles {
      role
    }
    user {
      userId
      nameSurname
      email
      isEmailConfirm
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedNotes {
            sharedNoteId
            toUserId
            created
            user {
              nameSurname
              email
            }
          }
        }
      }
    }
    userSettings {
      key
      value
    }
  }
}
`;

export const MUTATION_CHANGE_USER_SETTINGS = `
mutation($key: EnumUserSettingKey!, $value: String!) {
  changeUserSetting(key: $key, value: $value) {
    key
    value
  }
}
`;

export default {
    MUTATION_SIGNUP,
    MUTATION_SIGNIN,
    MUTATION_SIGNIN_VERIFY,
    MUTATION_EDIT_MYPROFILE,
    MUTATION_CHANGE_PASSWORD,
    MUTATION_EMAIL_ACTIVATION,
    MUTATION_FORGOT_PASSWORD,
    MUTATION_FORGOT_PASSWORD_CONFIRM,
    MUTATION_CHANGE_USER_SETTINGS,
};
