import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Note from './Note';
import CustomPaginator from "./customComponents/CustomPaginator";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {loadingShow} from "../actions/loadingActions";
import {TOAST_SHOW_ERROR, toastShow} from "../actions/toastActions";
import {sessionStorageNotify} from "../actions/sessionStorageActions";
import Functions from "../utils/Functions";
import Restful from "../utils/Restful";
import SessionStorage from "../utils/SessionStorage";

class NotesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: Functions.isNull(this.props.sessionStorageReducer.data) ? 1 : (this.props.sessionStorageReducer.data.noteList.skip / this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage),
        };
    }

    onPageChanged(activePage, event, value) {
        Restful.Graphql(Restful.Queries.NoteQuery.QUERY_NOTES, {
            noteSkip: (value - 1) * this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
            noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
        }, this, (response) => {
            const data = response.data.data;
            SessionStorage.set(SessionStorage.keys.noteList, data.notes, this);

            this.setState({
                activePage: parseInt(value, 10)
            });
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }

            this.setState({
                activePage: parseInt(activePage, 10)
            });
        });
    }

    isNoteExist() {
        if (!Functions.isNull(this.props.sessionStorageReducer.data)) {
            if (this.props.sessionStorageReducer.data.noteList.total > 0) {
                return true;
            }
        }

        return false;
    }

    render() {
        const emptyMessage = (
            <Box m={3}>
                <Typography variant="body1">There are no notes yet...</Typography>
            </Box>
        );

        const notesList = (
            <Box m={2}>
                <Grid container spacing={3}>
                    {
                        Functions.isNull(this.props.sessionStorageReducer.data) ? "" : this.props.sessionStorageReducer.data.noteList.noteList.map((item) =>
                            <Note key={item.noteId} note={item}/>
                        )
                    }
                </Grid>
                <br/>
                <CustomPaginator
                    recordCount={Functions.isNull(this.props.sessionStorageReducer.data) ? 0 : this.props.sessionStorageReducer.data.noteList.total}
                    recordCountPerPage={this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage}
                    activeSkip={Functions.isNull(this.props.sessionStorageReducer.data) ? 0 : this.props.sessionStorageReducer.data.noteList.skip}
                    onPageChanged={this.onPageChanged.bind(this)}
                />
            </Box>
        );

        return (
            <div>
                {this.isNoteExist() ? notesList : emptyMessage}
            </div>
        )
    }
}

NotesList.propTypes = {
    sessionStorageReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesList);
