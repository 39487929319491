import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {tempNoteChange} from '../actions/noteActions';
import '../App.css';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Functions from "../utils/Functions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {Divider} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import EmailValidator from "email-validator";
import Restful from "../utils/Restful";
import SessionStorage from "../utils/SessionStorage";
import {TOAST_SHOW_ERROR, TOAST_SHOW_SUCCESSFUL, toastShow} from "../actions/toastActions";
import {loadingShow} from "../actions/loadingActions";
import {sessionStorageNotify} from "../actions/sessionStorageActions";

class NoteForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            shareEmail: "",
            isErrorShareEmail: false,
        }
    }

    handleFormChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.props.tempNoteChange({
            ...this.props.tempNoteReducer,
            [name]: value,
            tagName: name
        });
    }

    handleShareEmailChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value,
        })
    }

    handleShareNoteClick(event) {
        event.preventDefault();

        if (Functions.isNullOrEmpty(this.state.shareEmail)) {
            this.setState({
                isErrorShareEmail: true,
            });

            return;
        }
        else {
            if (EmailValidator.validate(this.state.shareEmail)) {
                this.setState({
                    isErrorShareEmail: false,
                });
            }
            else {
                this.setState({
                    isErrorShareEmail: true,
                });

                return;
            }

            Restful.Graphql(Restful.Queries.SharedNoteQuery.MUTATION_START_SHARE_NOTE, {
                noteId: this.props.tempNoteReducer.noteId,
                email: this.state.shareEmail,
            }, this, (response) => {
                const data = response.data.data.startShareNote;

                const tempNoteList = SessionStorage.get(SessionStorage.keys.noteList, {
                    skip: 0,
                    length: 0,
                    total: 0,
                    noteList: [],
                });

                for (let i = 0; i < tempNoteList.noteList.length; i++) {
                    if (tempNoteList.noteList[i].noteId === this.props.tempNoteReducer.noteId) {
                        tempNoteList.noteList[i].sharedNotes.push(data);

                        break;
                    }
                }

                SessionStorage.set(SessionStorage.keys.noteList, tempNoteList, this);

                this.setState({
                    shareEmail: "",
                });

                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Note sharing successful!");
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                });
            });
        }
    }

    handleDeleteShareNoteClick(sharedNoteId, event) {
        event.preventDefault();

        Restful.Graphql(Restful.Queries.SharedNoteQuery.MUTATION_STOP_SHARE_NOTE, {
            sharedNoteId: sharedNoteId,
        }, this, (response) => {
            const data = response.data.data.stopShareNote;

            const tempNoteList = SessionStorage.get(SessionStorage.keys.noteList, {
                skip: 0,
                length: 0,
                total: 0,
                noteList: [],
            });

            for (let i = 0; i < tempNoteList.noteList.length; i++) {
                if (tempNoteList.noteList[i].noteId === this.props.tempNoteReducer.noteId) {
                    for (let j = 0; j < tempNoteList.noteList[i].sharedNotes.length; j++) {
                        if (tempNoteList.noteList[i].sharedNotes[j].sharedNoteId === data.sharedNoteId) {
                            tempNoteList.noteList[i].sharedNotes.splice(j, 1);

                            break;
                        }
                    }

                    break;
                }
            }

            SessionStorage.set(SessionStorage.keys.noteList, tempNoteList, this);

            this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Note sharing successful!");
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
            });
        });
    }

    getSharedNotes(noteId) {
        const tempNoteList = SessionStorage.get(SessionStorage.keys.noteList, {
            skip: 0,
            length: 0,
            total: 0,
            noteList: [],
        });

        let result = [];
        for (let i = 0; i < tempNoteList.noteList.length; i++) {
            if (tempNoteList.noteList[i].noteId === noteId) {
                result = tempNoteList.noteList[i].sharedNotes;

                break;
            }
        }

        return result;
    }

    isRenderSharedUsers(noteId) {
        const isNull = Functions.isNullOrEmpty(noteId);
        const isRecordFound = this.getSharedNotes(noteId).length > 0;
        const result = !isNull && isRecordFound;

        return result;
    }

    render() {
        return (
            <div>
                <form autoComplete="off">
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        value={this.props.tempNoteReducer.title}
                        onChange={this.handleFormChange.bind(this)}
                        multiline
                        rowsMax={4}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                    />
                    <TextField
                        id="note"
                        name="noteBody"
                        label="Enter a note"
                        value={this.props.tempNoteReducer.noteBody}
                        onChange={this.handleFormChange.bind(this)}
                        multiline
                        rows={4}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                    />
                    <FormControl  fullWidth margin="dense">
                        <InputLabel id="labelid-color">Color</InputLabel>
                        <Select
                            id="color"
                            name="color"
                            labelId="labelid-color"
                            value={this.props.tempNoteReducer.color}
                            onChange={this.handleFormChange.bind(this)}
                        >
                            <MenuItem value={"primary"} style={{backgroundColor: Functions.getColor("primary")}}>Primary</MenuItem>
                            <MenuItem value={"secondary"} style={{backgroundColor: Functions.getColor("secondary")}}>Secondary</MenuItem>
                            <MenuItem value={"success"} style={{backgroundColor: Functions.getColor("success")}}>Success</MenuItem>
                            <MenuItem value={"danger"} style={{backgroundColor: Functions.getColor("danger")}}>Danger</MenuItem>
                            <MenuItem value={"warning"} style={{backgroundColor: Functions.getColor("warning")}}>Warning</MenuItem>
                            <MenuItem value={"info"} style={{backgroundColor: Functions.getColor("info")}}>Info</MenuItem>
                            <MenuItem value={"white"} style={{backgroundColor: Functions.getColor("white")}}>Light</MenuItem>
                            <MenuItem value={"dark"} style={{backgroundColor: Functions.getColor("dark")}}>Dark</MenuItem>
                        </Select>
                    </FormControl>

                    <Box
                        style={{display: Functions.isNullOrEmpty(this.props.tempNoteReducer.noteId) ? "none" : "inherit"}}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                            <InputLabel htmlFor="shareEmail">Share With Email</InputLabel>
                            <OutlinedInput
                                id="shareEmail"
                                type="text"
                                name="shareEmail"
                                value={this.state.shareEmail}
                                onChange={this.handleShareEmailChange.bind(this)}
                                fullWidth
                                error={this.state.isErrorShareEmail}
                                disabled={this.state.isLoading}
                                margin="dense"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="shareEmail"
                                            onMouseDown={this.handleShareNoteClick.bind(this)}
                                            edge="end"
                                        >
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={120}
                            />
                        </FormControl>

                        <Box mt={2}
                             style={{display: this.isRenderSharedUsers(this.props.tempNoteReducer.noteId) ? "inherit" : "none"}}>
                            <Typography variant="h6">
                                Shared Users:
                            </Typography>
                            <Divider/>

                            <List component="nav">
                                {this.getSharedNotes(this.props.tempNoteReducer.noteId).map((row) => (
                                    <ListItem button key={row.sharedNoteId}>
                                        <ListItemText primary={row.user.email}/>
                                        <ListItemIcon>
                                            <IconButton
                                                aria-label="shareEmail"
                                                onMouseDown={this.handleDeleteShareNoteClick.bind(this, row.sharedNoteId)}
                                                edge="end"
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>

                </form>
            </div>
        );
    }
}

NoteForm.propTypes = {
    tempNoteReducer: PropTypes.shape({
        title: PropTypes.string.isRequired,
        noteBody: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    }).isRequired
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
    tempNoteChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteForm);
