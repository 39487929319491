import HomePage from "../components/pages/HomePage";
import SignIn from "../components/pages/authendication/SignIn";
import SignUp from "../components/pages/authendication/SignUp";
import MyProfile from "../components/pages/authendication/MyProfile";
import ForgotPassword from "../components/pages/authendication/ForgotPassword";
import UserRoles from "../components/pages/adminPages/UserRoles";
import UserSettings from "../components/pages/UserSettings";
import UnderConstruction from "../components/pages/UnderConstruction";

const RoutesWithOutProtect = [
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
    },
    {
        path: '/signup',
        name: 'signup',
        component: SignUp,
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/technologies',
        name: 'technologies',
        component: UnderConstruction,
    },
    {
        path: '/about',
        name: 'about',
        component: UnderConstruction,
    },
    {
        path: '/buyMeACoffee',
        name: 'buyMeACoffee',
        component: UnderConstruction,
    },
];

const RoutesWithProtect = [
    ...RoutesWithOutProtect,
    {
        path: '/',
        name: 'home',
        exact: true,
        component: HomePage,
    },
    {
        path: '/myProfile',
        name: 'myProfile',
        exact: true,
        component: MyProfile,
    },
    {
        path: '/userRoles',
        name: 'userRoles',
        exact: true,
        component: UserRoles,
    },
    {
        path: '/userSettings',
        name: 'userSettings',
        exact: true,
        component: UserSettings,
    },
];

const Routes = {
    RoutesWithOutProtect,
    RoutesWithProtect
}

export default Routes;
