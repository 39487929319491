export const MUTATION_START_SHARE_NOTE = `
mutation($noteId: ID!, $email: String!) {
  startShareNote(noteId: $noteId, email: $email) {
    sharedNoteId
    toUserId
    created
    user {
      nameSurname
      email
    }
  }
}
`;

export const MUTATION_STOP_SHARE_NOTE = `
mutation($sharedNoteId: ID!) {
  stopShareNote(sharedNoteId: $sharedNoteId) {
    sharedNoteId
    noteId
    toUserId
    created
    user {
      nameSurname
      email
    }
  }
}
`;

export default {
    MUTATION_START_SHARE_NOTE,
    MUTATION_STOP_SHARE_NOTE,
};
