import React, {Component} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import Functions from "../../utils/Functions";

let paginatorParameters = {
    recordCount: 0,
    recordCountPerPage: 0,
    activeSkip: 0,

    activePage: 1,
    pageCount: 0,
}

class CustomPaginator extends Component {
    calculatePaginatorParameters(props) {
        paginatorParameters = {
            ...paginatorParameters,
            recordCount: props.recordCount,
            recordCountPerPage: props.recordCountPerPage,
            activeSkip: props.activeSkip,
        }

        let diff = (props.recordCount % props.recordCountPerPage);
        if (Functions.isNaN(diff)) {
            diff = 0;
        }

        paginatorParameters.pageCount = (props.recordCount - diff) / props.recordCountPerPage;
        if (Functions.isNaN(paginatorParameters.pageCount)) {
            paginatorParameters.pageCount = 0;
        }
        if (diff > 0) {
            paginatorParameters.pageCount = paginatorParameters.pageCount + 1;
        }

        if (paginatorParameters.activeSkip === 0) {
            paginatorParameters.activePage = 1;
        }
        else {
            paginatorParameters.activePage = (paginatorParameters.activeSkip / paginatorParameters.recordCountPerPage) + 1;
        }
    }

    render() {
        this.calculatePaginatorParameters(this.props);

        const paginatorComponent = (
            <>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Pagination showFirstButton showLastButton
                                count={paginatorParameters.pageCount}
                                page={paginatorParameters.activePage}
                                onChange={this.props.onPageChanged.bind(this, paginatorParameters.activePage)}
                    />
                </Box>
            </>
        );

        return (
            <>
                {paginatorParameters.pageCount > 1 ? paginatorComponent : null}
            </>
        );
    }
}

export default CustomPaginator;
