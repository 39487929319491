export const QUERY_NOTES = `
query($noteSkip: Int, $noteLimit: Int!) {
  notes(skip: $noteSkip, limit: $noteLimit) {
    skip
    length
    total
    noteList {
      noteId
      title
      noteBody
      color
      noteType
      userId
      created
      updated
      sharedUsers {
        userId
        nameSurname
        email
        isEmailConfirm
        lastLogin
      }
    }
  }
}
`;

export const MUTATION_ADD_NOTE = `
mutation($title: String, $noteBody: String, $color: EnumNoteColor!, $noteType: EnumNoteType!, $noteSkip: Int, $noteLimit: Int!) {
  addNote(data: { title: $title, noteBody: $noteBody, color: $color, noteType: $noteType}) {
    userId
    user {
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedUsers {
            userId
            nameSurname
            email
            isEmailConfirm
            lastLogin
          }
        }
      }
    }
  }
}
`;

export const MUTATION_EDIT_NOTE = `
mutation(
  $noteId: ID!
  $title: String
  $noteBody: String
  $color: EnumNoteColor!
  $noteType: EnumNoteType!
  $noteSkip: Int
  $noteLimit: Int!
) {
  editNote(
    data: {
      noteId: $noteId
      title: $title
      noteBody: $noteBody
      color: $color
      noteType: $noteType
    }
  ) {
    userId
    user {
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedUsers {
            userId
            nameSurname
            email
            isEmailConfirm
            lastLogin
          }
        }
      }
    }
  }
}
`;

export const MUTATION_DELETE_NOTE = `
mutation($noteId: ID!, $noteSkip: Int $noteLimit: Int!) {
  deleteNote(noteId: $noteId) {
    userId
    user {
      notes(skip: $noteSkip, limit: $noteLimit) {
        skip
        length
        total
        noteList {
          noteId
          title
          noteBody
          color
          noteType
          userId
          created
          updated
          sharedUsers {
            userId
            nameSurname
            email
            isEmailConfirm
            lastLogin
          }
        }
      }
    }
  }
}
`;

export default {
    QUERY_NOTES,
    MUTATION_ADD_NOTE,
    MUTATION_EDIT_NOTE,
    MUTATION_DELETE_NOTE,
};
