import React, { Component } from 'react';
import {connect} from "react-redux";
import {loadingShow} from "../../../actions/loadingActions";
import Box from '@material-ui/core/Box';
import Header from '../../Header';
import Footer from '../../Footer';
import SharedComponentUtils from "../../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";
import {withStyles} from "@material-ui/styles";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Restful from "../../../utils/Restful";
import {
    TOAST_SHOW_ERROR,
    TOAST_SHOW_INFO,
    TOAST_SHOW_SUCCESSFUL,
    toastShow,
} from "../../../actions/toastActions";
import Functions from "../../../utils/Functions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Constants from "../../../utils/Constants";

const styles = theme => ({
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(3),
    },
});

class UserRoles extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            users: [],
            isLoading: false,
            isSelected: false,
            selectedUser: null,
            Admin: false,
            User: false,
            Demo: false,
        };
    }

    componentDidMount() {
        SharedComponentUtils.loginCheck(this);

        Restful.Graphql(Restful.Queries.AdminQuery.QUERY_USERS, {}, this, (response) => {
            const data = response.data.data.users;

            if (Functions.isNull(data)) {
                this.props.toastShow(TOAST_SHOW_INFO, "User not found.");
                return;
            }
            else if (data.length === 0) {
                this.props.toastShow(TOAST_SHOW_INFO, "User not found.");
                return;
            }

            this.setState({
                users: data,
            });
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        }, (isLoading) => {
            this.setState({
                isLoading: isLoading,
            });
        });
    }

    getRoleView(user) {
        let result = "";
        user.roles.forEach(function (item, index, array) {
            result += item.role;
            if (index + 1 !== array.length) {
                result += ", ";
            }
        });

        return result;
    }

    onClickButton_Select(user) {
        this.setState({
            isSelected: true,
            selectedUser: user,
        });

        const allRoles = Object.keys(Constants.Roles);
        for (let i = 0; i < allRoles.length; i++) {
            const isRoleTrue = user.roles.some((item) => {
                return item.role === allRoles[i];
            });
            this.setState({
                [allRoles[i]]: isRoleTrue,
            });
        }
    }

    onClickButton_Back() {
        this.setState({
            isSelected: false,
            selectedUser: null,
        });
    }

    onChangeRole(event) {
        const changedRole = event.target.name;
        const changedValue = event.target.checked;

        if (changedValue) {
            Restful.Graphql(Restful.Queries.AdminQuery.MUTATION_ADD_ROLE, {role: changedRole}, this, (response) => {
                //const data = response.data.data.addRole;

                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "User role added");

                this.setState({
                    [changedRole]: changedValue,
                });
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                });
            });
        }
        else {
            Restful.Graphql(Restful.Queries.AdminQuery.MUTATION_DELETE_ROLE, {role: changedRole}, this, (response) => {
                //const data = response.data.data.addRole;

                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "User role deleted");

                this.setState({
                    [changedRole]: changedValue,
                });
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                });
            });
        }
    }

    render() {
        return (
            <>
                <Header/>
                <Box mt={6}>
                    <Box style={{display: this.state.isSelected ? null : "none"}}>
                        <Paper elevation={3}>
                            <FormControl component="fieldset" className={this.props.classes.formControl}>
                                <FormLabel component="legend">Roles:</FormLabel>
                                <FormGroup>
                                    {Object.keys(Constants.Roles).map(item => (
                                        <FormControlLabel key={item}
                                                          control={<Checkbox checked={this.state[item]} onChange={this.onChangeRole.bind(this)} name={item} />}
                                                          label={item}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </Paper>

                        <br />
                        <Box style={{textAlign: "center"}}>
                            <Button variant="contained" onClick={this.onClickButton_Back.bind(this)}>Back</Button>
                        </Box>
                    </Box>

                    <TableContainer component={Paper} style={{display: this.state.isSelected ? "none" : null}}>
                        <Table className={this.props.classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Processes</TableCell>
                                    <TableCell>Name Surname</TableCell>
                                    <TableCell align="left">E-Mail</TableCell>
                                    <TableCell align="left">E-Mail Confirm</TableCell>
                                    <TableCell align="left">Created</TableCell>
                                    <TableCell align="left">Last Login</TableCell>
                                    <TableCell align="left">Updated</TableCell>
                                    <TableCell align="left">Roles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.users.map((row) => (
                                    <TableRow key={row.email}>
                                        <TableCell component="th" scope="row">
                                            <Button variant="contained" onClick={this.onClickButton_Select.bind(this, row)}>Select</Button>
                                        </TableCell>
                                        <TableCell align="left">{row.nameSurname}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">{row.isEmailConfirm.toString()}</TableCell>
                                        <TableCell align="left">{Functions.getFormatedDate(row.created)}</TableCell>
                                        <TableCell align="left">{Functions.getFormatedDate(row.lastLogin)}</TableCell>
                                        <TableCell align="left">{Functions.getFormatedDate(row.updated)}</TableCell>
                                        <TableCell align="left">{this.getRoleView(row)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserRoles));
