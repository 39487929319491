import Functions from "./Functions";
import SessionStorage from "./SessionStorage";
import LocalStorage from "./LocalStorage";
import Restful from "./Restful";

function signInResponseHandler(signInResponse, _this) {
    const token = signInResponse.token;
    const userInfo = {
        userId: signInResponse.user.userId,
        nameSurname: signInResponse.user.nameSurname,
        email: signInResponse.user.email,
        isEmailConfirm: signInResponse.user.isEmailConfirm,
        roles: signInResponse.roles,
    }

    LocalStorage.set(LocalStorage.keys.token, token);
    SessionStorage.set(SessionStorage.keys.isSignInCheckFromServer, true, _this);
    Restful.setGraphqlToken(token);

    let tempUserSettings = SessionStorage.getData().userSettings;
    signInResponse.userSettings.forEach(function (item, index, array) {
        tempUserSettings = {
            ...tempUserSettings,
            [item.key]: JSON.parse(item.value),
        }
    });
    SessionStorage.set(SessionStorage.keys.userSettings, tempUserSettings, _this);

    SessionStorage.set(SessionStorage.keys.userInfo, userInfo, _this);
    SessionStorage.set(SessionStorage.keys.noteList, signInResponse.user.notes, _this);
}

function loginCheck(_this) {
    const token = LocalStorage.get(LocalStorage.keys.token);
    if (Functions.isNullOrEmpty(token)) {
        signOut(_this);
    }
    else if (!SessionStorage.get(SessionStorage.keys.isSignInCheckFromServer, false)) {
        Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_SIGNIN_VERIFY, {
            noteSkip: 0,
            noteLimit: _this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
        }, _this, (response) => {
            const data = response.data.data.signInVerify;

            signInResponseHandler(data, _this);
        }, (errorMessage, isUnhandled, errors) => {
            if (isUnhandled === false) {
                console.error(errorMessage);
            }
            else {
                console.error("UnHandled Error: ", errorMessage);
            }

            if (!Functions.isNull(errors)) {
                if (errorMessage === "Network Error") {
                    signOut(_this);
                }
                if (errors.length > 0) {
                    if (errors[0].extensions.code === "UNAUTHENTICATED") {
                        signOut(_this);
                        SharedComponentUtils.pageRefresh();
                    }
                }
            }
        });
    }
    else {
        _this.props.sessionStorageNotify();
    }
}

function signOut(_this) {
    LocalStorage.remove(LocalStorage.keys.token);
    SessionStorage.clearAll(_this);
    Restful.setGraphqlToken(null);
    _this.props.history.push("/signin");
}

function checkRole(_this, role) {
    if (!Functions.isNull(_this.props.sessionStorageReducer.data)) {
        if (!Functions.isNull(_this.props.sessionStorageReducer.data.userInfo)) {
            if (_this.props.sessionStorageReducer.data.userInfo.roles.some(item => item.role === role)) {
                return true;
            }
        }
    }

    return false;
}

function pageRefresh() {
    window.location.reload(true);
}

const SharedComponentUtils = {
    signInResponseHandler,
    loginCheck,
    signOut,
    checkRole,
    pageRefresh,
}

export default SharedComponentUtils;
