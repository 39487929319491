import {
    LOADING_SHOW
} from '../actions/loadingActions';
import InitialValues from "../utils/InitialValues";

export default (previousState = InitialValues.getLoading(), action) => {
    switch (action.type) {
        case LOADING_SHOW:
            return {
                ...previousState,
                isShow: action.payload.isShow
            };
        default:
            return previousState;
    }
}
