import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Functions from "../utils/Functions";
import {dialog, SHOW_DIALOG_EDIT_NOTE} from "../actions/dialogActions";
import {tempNoteChange} from "../actions/noteActions";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

class Note extends Component {
    render() {
        const onClickCard = () => {
            this.props.tempNoteChange(this.props.note);
            this.props.dialog(SHOW_DIALOG_EDIT_NOTE);
        };

        return (
            <Grid item xs={3}>
                <Card style={{cursor: "pointer", backgroundColor: Functions.getColor(this.props.note.color)}} onClick={onClickCard.bind(this)}>
                    <Box display={Functions.isNullOrEmpty(this.props.note.title) ? "none" : null}>
                        <CardHeader title={this.props.note.title} titleTypographyProps={{variant: "subtitle1", noWrap: true}} />
                        <Divider/>
                    </Box>
                    <CardContent>
                        <Typography variant="body2" noWrap>
                            {this.props.note.noteBody}
                        </Typography>
                        <br />
                        <Typography variant="caption" noWrap>
                            Created: {Functions.getFormatedDate(this.props.note.created)}
                        </Typography>
                        <br />
                        <Typography variant="caption" noWrap>
                            Updated: {Functions.getFormatedDate(this.props.note.updated)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

Note.propTypes = {
    note: PropTypes.shape({
        noteId: PropTypes.string.isRequired
    }).isRequired
};

const mapStateToProp = (state) => {
    return state;
};

const mapDispatchToProps = {
    dialog,
    tempNoteChange
};

export default connect(mapStateToProp, mapDispatchToProps)(Note);
