import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {CLOSE_DIALOG, dialog} from "../../../actions/dialogActions";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {withStyles} from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function PaperComponent(props) {
    return (
        <Draggable handle="#dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

class CustomDialog extends Component {
    onDialogClose() {
        this.props.dialog(CLOSE_DIALOG);
    }

    render() {
        const DialogTitle = withStyles(styles)((props) => {
            const { children, classes, onClose, ...other } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {this.props.content.isShowCloseButton ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

        return (
            <>
                <Dialog
                    open={this.props.content.isShow}
                    onClose={this.onDialogClose.bind(this)}
                    PaperComponent={PaperComponent}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title" style={{cursor: 'move'}} onClose={this.onDialogClose.bind(this)}>
                        {this.props.content.dialogTitle}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box display={this.props.content.dialogContentText === null ? "none" : null}>
                            <DialogContentText id="dialog-description">
                                {this.props.content.dialogContentText}
                            </DialogContentText>
                        </Box>
                        {this.props.content.dialogContent}
                    </DialogContent>
                    <DialogActions>
                        {this.props.content.dialogAction}
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

CustomDialog.propTypes = {
    dialogReducer: PropTypes.shape({
        type: PropTypes.string.isRequired
    }).isRequired,
    content: PropTypes.shape({
        isShow: PropTypes.bool.isRequired,
        dialogTitle: PropTypes.string.isRequired,
        isShowCloseButton: PropTypes.bool.isRequired,
        dialogContent: PropTypes.object,
        dialogContentText: PropTypes.string,
        dialogAction: PropTypes.object,
    }).isRequired,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    dialog
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomDialog));
