import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {CLOSE_MODAL, modal} from "../../../actions/modalActions";
import Modal from '@material-ui/core/Modal';
import {withStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const styles = theme => ({
    paper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        border: "0px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1, 1)
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle(isRandom) {
    let top = 50;
    let left = 50;

    if (isRandom) {
        top += rand();
        left += rand();
    }

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

class CustomModal extends Component {
    onDialogClose() {
        this.props.dialog(CLOSE_MODAL);
    }

    render() {
        return (
            <>
                <Modal
                    open={this.props.content.isShow}
                    onClose={this.onDialogClose.bind(this)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={getModalStyle(true)} className={this.props.classes.paper}>

                        <Box display="flex" mt={1}>
                            <Box flexGrow={1}>
                                <Tooltip title={this.props.content.modalTitle} placement="top-start">
                                    <Typography variant="h5" gutterBottom noWrap>
                                        {this.props.content.modalTitle}
                                    </Typography>
                                </Tooltip>

                            </Box>
                            <Box display={this.props.content.isShowCloseButton ? "none" : null}>
                                <IconButton aria-label="delete" onClick={this.onDialogClose.bind(this)}>
                                    <HighlightOffIcon/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box>
                            <Divider/>
                        </Box>

                        {this.props.content.modalContent}
                    </div>
                </Modal>
            </>
        );
    }
}

CustomModal.propTypes = {
    dialogReducer: PropTypes.shape({
        type: PropTypes.string.isRequired
    }).isRequired,
    content: PropTypes.shape({
        isShow: PropTypes.bool.isRequired,
        modalTitle: PropTypes.string.isRequired,
        isShowCloseButton: PropTypes.bool.isRequired,
        modalContent: PropTypes.object.isRequired,
    }).isRequired,
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    modal
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomModal));
