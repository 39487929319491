import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class Loading extends Component {
    render() {
        return (
            <>
                <Backdrop className={this.props.classes.backdrop} open={this.props.loadingReducer.isShow}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(withStyles(styles)(Loading));
