import React, { Component } from 'react';
import {connect} from "react-redux";
import {loadingShow} from "../../actions/loadingActions";
import Box from '@material-ui/core/Box';
import Header from '../Header';
import Footer from '../Footer';
import {sessionStorageNotify} from "../../actions/sessionStorageActions";
import {withStyles} from "@material-ui/styles";
import {withRouter} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Image from '../../styles/pictures/under-construction.png';
import Functions from "../../utils/Functions";

const styles = theme => ({
    paperComponent: {
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundColor: Functions.getColor("dark"),
        height: 380,
    },
});

class UnderConstruction extends Component {
    render() {
        return (
            <>
                <Header/>
                <Box mt={6}>
                    <Paper className={this.props.classes.paperComponent}>

                    </Paper>
                </Box>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(UnderConstruction)));
