import {combineReducers} from 'redux';
import dialogReducer from './dialogReducer';
import modalReducer from './modalReducer';
import toastReducer from "./toastReducer";
import tempNoteReducer from "./tempNoteReducer";
import loadingReducer from "./loadingReducer";
import sessionStorageReducer from "./sessionStorageReducer";

export default combineReducers({
    toastReducer,
    loadingReducer,
    dialogReducer,
    modalReducer,
    tempNoteReducer,
    sessionStorageReducer,
});
