export const TOAST_SHOW_INFO = "TOAST_SHOW_INFO";
export const TOAST_SHOW_SUCCESSFUL = "TOAST_SHOW_SUCCESSFUL";
export const TOAST_SHOW_WARNING = "TOAST_SHOW_WARNING";
export const TOAST_SHOW_ERROR = "TOAST_SHOW_ERROR";
export const TOAST_SHOW_DEFAULT = "TOAST_SHOW_DEFAULT";
export const TOAST_CLOSE = "TOAST_CLOSE";

export function toastShow(type, message) {
    return {
        type: type,
        payload: {
            isOpen: true,
            type: type,
            message: message
        }
    }
}

export function toastClose() {
    return {
        type: TOAST_CLOSE,
        payload: {
            isOpen: false,
            type: TOAST_CLOSE
        }
    }
}
