import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {loadingShow} from "../../../actions/loadingActions";
import {TOAST_SHOW_ERROR, TOAST_SHOW_SUCCESSFUL, TOAST_SHOW_WARNING, toastShow} from "../../../actions/toastActions";
import Restful from "../../../utils/Restful";
import Functions from "../../../utils/Functions";
import EmailValidator from "email-validator";
import { withRouter } from "react-router-dom";
import SharedComponentUtils from "../../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";
import {Box} from "@material-ui/core";

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class ForgotPassword extends Component {
    constructor(props, context) {
        super(props, context);

        const urlForgotPasswordCode = new URLSearchParams(this.props.location.search).get("forgotpasswordcode");
        const urlEmail = new URLSearchParams(this.props.location.search).get("email");
        const isForgotPasswordConfirm = !Functions.isNullOrEmpty(urlForgotPasswordCode) && !Functions.isNullOrEmpty(urlEmail);

        this.state = {
            isForgotPasswordConfirm: isForgotPasswordConfirm,
            forgotPasswordCode: isForgotPasswordConfirm ? urlForgotPasswordCode : '',
            email: isForgotPasswordConfirm ? urlEmail : '',
            password: '',
            confirmPassword: '',
            isLoading: false,
            isErrorForgotPasswordCode: false,
            isErrorEmail: false,
            isErrorPassword: false,
            isErrorConfirmPassword: false,
            isDisableSubmitButton: true,
        }
    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.name === "confirmPassword") {
            if (this.state.password !== event.target.value) {
                this.setState({
                    isErrorConfirmPassword: true,
                    isDisableSubmitButton: true
                });
            }
            else {
                this.setState({
                    isErrorConfirmPassword: false,
                    isDisableSubmitButton: false
                });
            }
        }

        if (event.target.name === "email") {
            if (!EmailValidator.validate(event.target.value)) {
                this.setState({
                    isErrorEmail: true,
                    isDisableSubmitButton: true,
                });
            }
            else {
                this.setState({
                    isErrorEmail: false,
                    isDisableSubmitButton: false,
                });
            }
        }
    }

    formValidate() {
        let result = true;

        if (this.state.isForgotPasswordConfirm) {
            this.setState({
                isErrorForgotPasswordCode: Functions.isNullOrEmpty(this.state.forgotPasswordCode),
                isErrorEmail: Functions.isNullOrEmpty(this.state.email),
                isErrorPassword: Functions.isNullOrEmpty(this.state.password),
                isErrorConfirmPassword: Functions.isNullOrEmpty(this.state.confirmPassword),
            });

            if (Functions.isNullOrEmpty(this.state.forgotPasswordCode)
                || Functions.isNullOrEmpty(this.state.email)
                || Functions.isNullOrEmpty(this.state.password)
                || Functions.isNullOrEmpty(this.state.confirmPassword)
            ) {
                result = false;
            }
        }
        else {
            this.setState({
                isErrorEmail: Functions.isNullOrEmpty(this.state.email),
            });

            if (Functions.isNullOrEmpty(this.state.email)) {
                result = false;
            }
        }

        if (!EmailValidator.validate(this.state.email)) {
            this.setState({
                isErrorEmail: true
            });
            result = false;
        }

        return result;
    }

    onSubmit(event) {
        event.preventDefault();

        if (!this.formValidate()) {
            return;
        }

        if (this.state.isForgotPasswordConfirm) {
            Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_FORGOT_PASSWORD_CONFIRM, {
                forgotPasswordCode: this.state.forgotPasswordCode,
                email: this.state.email,
                newPassword: this.state.password,
                noteSkip: 0,
                noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
            }, this, (response) => {
                const data = response.data.data.forgotPasswordConfirm;

                SharedComponentUtils.signInResponseHandler(data, this);

                if (data.user.isEmailConfirm) {
                    this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Password change successful!");
                }
                else {
                    this.props.toastShow(TOAST_SHOW_WARNING, "Email is not activated! Please activate your email address.");
                }

                this.setState({
                    isErrorPassword: false,
                    isErrorConfirmPassword: false,
                });

                this.props.history.push("/");
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                    isDisableSubmitButton: isLoading,
                });
            });
        }
        else {
            Restful.Graphql(Restful.Queries.AuthenticationQuery.MUTATION_FORGOT_PASSWORD, {
                email: this.state.email,
            }, this, (response) => {
                this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Send E-Mail your address!");

                this.setState({
                    isErrorPassword: false,
                    isErrorConfirmPassword: false,
                });

                this.props.history.push("/");
            }, (errorMessage, isUnhandled) => {
                if (isUnhandled === false) {
                    this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
                }
                else {
                    this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
                }
            }, (isLoading) => {
                this.setState({
                    isLoading: isLoading,
                    isDisableSubmitButton: isLoading,
                });
            });
        }
    }

    render() {
        return (
            <>
                <Container component="main" maxWidth="xs">
                    <div className={this.props.classes.paper}>
                        <Avatar className={this.props.classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot Password
                        </Typography>
                        <form className={this.props.classes.form} noValidate onSubmit={this.onSubmit.bind(this)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                disabled={this.state.isForgotPasswordConfirm}
                                value={this.state.email}
                                error={this.state.isErrorEmail}
                                onChange={this.onChange.bind(this)}
                            />
                            <Box style={{display: this.state.isForgotPasswordConfirm ? null : "none"}}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="forgotPasswordCode"
                                    label="Forgot Password Code"
                                    name="forgotPasswordCode"
                                    autoComplete="forgotPasswordCode"
                                    disabled
                                    value={this.state.forgotPasswordCode}
                                    error={this.state.isErrorForgotPasswordCode}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type="password"
                                    id="password"
                                    autoComplete="password"
                                    value={this.state.password}
                                    error={this.state.isErrorPassword}
                                    onChange={this.onChange.bind(this)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="confirm-password"
                                    value={this.state.confirmPassword}
                                    error={this.state.isErrorConfirmPassword}
                                    onChange={this.onChange.bind(this)}
                                />
                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={this.state.isDisableSubmitButton}
                                className={this.props.classes.submit}
                            >
                                {this.state.isForgotPasswordConfirm ? "Change Password" : "Forgot Password"}
                            </Button>
                        </form>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ForgotPassword)));
