import {
    SESSION_STORAGE_NOTIFY
} from '../actions/sessionStorageActions';
import 'react-toastify/dist/ReactToastify.min.css';
import InitialValues from "../utils/InitialValues";
import SessionStorage from "../utils/SessionStorage";

export default (previousState = InitialValues.getSessionStorage(), action) => {
    switch (action.type) {
        case SESSION_STORAGE_NOTIFY:
            return {
                ...previousState,
                type: action.type,
                data: SessionStorage.getData(),
            };
        default:
            return {
                ...previousState,
                type: action.type,
                data: SessionStorage.getData(),
            };
    }
}
