import axios from "axios";
import Functions from "./Functions";
import Queries from "../queries";
import LocalStorage from "./LocalStorage";
import SharedComponentUtils from "./SharedComponentUtils";

const URL = "https://realtime-notepad-backend.herokuapp.com/graphql";

let configRestGraphql = {
    method: "post",
    url: URL,
    timeout: 20000,
    responseType: 'json',
    onUploadProgress: function (progressEvent) {
        //console.log("onUploadProgress", progressEvent);
    },
    onDownloadProgress: function (progressEvent) {
        //console.log("onDownloadProgress", progressEvent);
    },
    maxRedirects: 5, // default
    headers: { Authorization: `${LocalStorage.get(LocalStorage.keys.token)}` }
};

function setGraphqlToken(token) {
    axios.defaults.headers.common['Authorization'] = token;
}

function Graphql(query, variables, _this, responseCallback, errorCallback, loadingCallback) {
    if (!Functions.isNull(_this.props)) {
        _this.props.loadingShow(true);
    }

    if (!Functions.isNull(loadingCallback)) {
        loadingCallback(true);
    }

    const token = LocalStorage.get(LocalStorage.keys.token);
    configRestGraphql = {
        ...configRestGraphql,
        headers: { Authorization: `${token}` }
    };

    return axios({
        ...configRestGraphql,
        data: {
            operationName: null,
            variables: variables,
            query: query
        }
    })
        .then(function (response) {
            console.log("Graphql Response", response);

            if (!Functions.isNull(_this.props)) {
                _this.props.loadingShow(false);
            }

            if (!Functions.isNull(loadingCallback)) {
                loadingCallback(false);
            }

            if (!Functions.isNull(response.data.errors)) {
                if (!Functions.isNull(errorCallback)) {
                    const errorMessage = _errorToString(response.data.errors);
                    errorCallback(errorMessage, false, response.data.errors);

                    for (let i = 0; i < response.data.errors.length; i++) {
                        if (response.data.errors[i].extensions.code === "UNAUTHENTICATED") {
                            SharedComponentUtils.signOut(_this);
                            break;
                        }
                    }
                }
            } else if (!Functions.isNull(responseCallback)) {
                responseCallback(response);
            }
        })
        .catch(function (error) {
            console.log("Graphql Error Response", error);

            if (!Functions.isNull(_this.props)) {
                _this.props.loadingShow(false);
            }

            if (!Functions.isNull(loadingCallback)) {
                loadingCallback(false);
            }

            if (!Functions.isNull(errorCallback)) {
                if (error.response) {
                    const errorMessage = _errorToString(error.response.data.errors);
                    errorCallback(errorMessage, true, error.response.data.errors);
                } else {
                    errorCallback(error.message, true, error);
                }
            }
        });
}

function _errorToString(errors) {
    if (errors.length === 1)
        return errors[0].message;
    else {
        let message = "";
        for (let i = 0; i < errors.length; i++) {
            message += (i + 1) + ") " + errors[i].message;
            if (i !== errors.length - 1) {
                message += " - ";
            }
        }

        return message;
    }
}

const Restful = {
    setGraphqlToken,
    Graphql,
    Queries,
};

export default Restful;
