import {
    TEMP_NOTE_CHANGE
} from '../actions/actions';
import InitialValues from "../utils/InitialValues";

export default (previousState = InitialValues.getTempNote(), action) => {
    switch (action.type) {
        case TEMP_NOTE_CHANGE:
            return {
                ...previousState,
                ...action.payload
            };
        default:
            return previousState;
    }
}