import Functions from "./Functions";

class InitialValues {
    static getToast() {
        return Functions.DeepCopy(initialState_toast);
    }

    static getLoading() {
        return Functions.DeepCopy(initialState_loading);
    }

    static getTempNote() {
        return Functions.DeepCopy(initialState_tempNote);
    }

    static getDialog() {
        return Functions.DeepCopy(initialState_dialog);
    }

    static getModal() {
        return Functions.DeepCopy(initialState_modal);
    }

    static getSessionStorage() {
        return Functions.DeepCopy(initialState_sessionStorage);
    }
}

export default InitialValues;

const initialState_loading = {
    isShow: false
};

const initialState_toast = {
    isOpen: false,
    type: "",
    message: "",
    options: {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        pauseOnVisibilityChange: true,
        newestOnTop: false,
        rtl: false
    }
};

const initialState_tempNote = {
    noteId: "",
    title: "",
    noteBody: "",
    color: "white",
    noteType: "text",
    createdDateTime: "",
    modifiedDateTime: "",
    tagName: "",
};

const initialState_dialog = {
    type: ""
};

const initialState_modal = {
    type: ""
};

const initialState_sessionStorage = {
    type: "",
    data: null,
};
