import AuthenticationQuery from "./authenticationQuery";
import AdminQuery from "./adminQuery";
import NoteQuery from "./noteQuery";
import SharedNoteQuery from "./sharedNoteQuery";

const Queries = {
    AuthenticationQuery,
    AdminQuery,
    NoteQuery,
    SharedNoteQuery,
};

export default Queries;
