import {
    TEMP_NOTE_CHANGE
} from './actions';
import InitialValues from "../utils/InitialValues";


export function tempNoteChange(tempNoteModel = InitialValues.getTempNote()) {
    return {
        type: TEMP_NOTE_CHANGE,
        payload: {
            ...tempNoteModel
        }
    }
}
