import {
    CLOSE_DIALOG,
    SHOW_DIALOG_ADD_NOTE,
    SHOW_DIALOG_EDIT_NOTE
} from "../actions/dialogActions";
import InitialValues from "../utils/InitialValues";

export default (previousState = InitialValues.getDialog(), action) => {
    if (
        action.type === CLOSE_DIALOG
        || action.type === SHOW_DIALOG_ADD_NOTE
        || action.type === SHOW_DIALOG_EDIT_NOTE
    ) {
        return {
            ...previousState,
            type: action.type
        };
    } else {
        return previousState;
    }
}