import SessionStorage from "../utils/SessionStorage";

export const SESSION_STORAGE_NOTIFY = "SESSION_STORAGE_NOTIFY";

export function sessionStorageNotify() {
    return {
        type: SESSION_STORAGE_NOTIFY,
        payload: {
            data: SessionStorage.getData(),
        }
    }
}
