import React, { Component } from 'react';
import {connect} from "react-redux";
import {loadingShow} from "../../actions/loadingActions";
import NoteList from '../NoteList';
import Box from '@material-ui/core/Box';
import Header from '../Header';
import Footer from '../Footer';
import SharedComponentUtils from "../../utils/SharedComponentUtils";
import {sessionStorageNotify} from "../../actions/sessionStorageActions";

class HomePage extends Component {
    componentDidMount() {
        SharedComponentUtils.loginCheck(this);
    }

    render() {
        return (
            <>
                <Header/>
                <Box mt={6}>
                    <NoteList/>
                </Box>
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
