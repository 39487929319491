export const QUERY_USERS = `
query {
  users {
    userId
    nameSurname
    email
    isEmailConfirm
    lastLogin
    created
    updated
    roles {
      role
    }
  }
}
`;

export const MUTATION_ADD_ROLE = `
mutation($role: EnumRole!) {
  addRole(role: $role)
}
`;

export const MUTATION_DELETE_ROLE = `
mutation($role: EnumRole!) {
  deleteRole(role: $role)
}
`;

export default {
    QUERY_USERS,
    MUTATION_ADD_ROLE,
    MUTATION_DELETE_ROLE,
};
