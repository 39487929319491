import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CLOSE_MODAL, modal, SHOW_MODAL_EDIT_NOTE} from "../../../actions/modalActions";
import CustomModal from "./CustomModal";
import NoteForm from "../../NoteForm";
import Button from "@material-ui/core/Button";
import {TOAST_SHOW_SUCCESSFUL, toastShow} from "../../../actions/toastActions";
import {loadingShow} from "../../../actions/loadingActions";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

class AddNoteDialog extends Component {
    ModalContent() {
        const title = "Edit Note";
        return {
            isShow: (this.props.modalReducer.type === SHOW_MODAL_EDIT_NOTE),
            modalTitle: title,
            isShowCloseButton: true,
            modalContent: (
                <>
                    <Box m={1}>
                        <NoteForm/>
                        <Box mt={2}>
                            <Divider/>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Box ml={1} mt={2}>
                                <Button variant="contained" color="primary" onClick={this.onClickButton_EditNote.bind(this)}>{title}</Button>
                            </Box>
                            <Box ml={1} mt={2}>
                                <Button variant="contained" color="secondary" onClick={this.onClickButton_DeleteNote.bind(this)}>Delete Note</Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )
        }
    }

    onClickButton_EditNote(event) {
        this.props.loadingShow(true);
        this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Edit note successful...");
        this.props.modal(CLOSE_MODAL);
        this.props.loadingShow(false);

        event.preventDefault();
    }

    onClickButton_DeleteNote(event) {
        this.props.loadingShow(true);
        this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Delete note successful...");
        this.props.modal(CLOSE_MODAL);
        this.props.loadingShow(false);

        event.preventDefault();
    }

    render() {
        return (
            <>
                <CustomModal content={this.ModalContent()} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    modal,
    toastShow,
    loadingShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNoteDialog);
