import React, {Component} from 'react';
import {connect} from 'react-redux';
import {dialog, CLOSE_DIALOG, SHOW_DIALOG_ADD_NOTE} from "../../../actions/dialogActions";
import {toastShow, TOAST_SHOW_SUCCESSFUL, TOAST_SHOW_ERROR} from "../../../actions/toastActions";
import {loadingShow} from "../../../actions/loadingActions";
import CustomDialog from "./CustomDialog";
import NoteForm from "../../NoteForm";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Restful from "../../../utils/Restful";
import {sessionStorageNotify} from "../../../actions/sessionStorageActions";
import SessionStorage from "../../../utils/SessionStorage";
import {withRouter} from "react-router-dom";

class AddNoteDialog extends Component {
    DialogContent() {
        const title = "Add Note";
        return {
            isShow: (this.props.dialogReducer.type === SHOW_DIALOG_ADD_NOTE),
            dialogTitle: title,
            isShowCloseButton: true,
            dialogContentText: null,
            dialogContent: (
                <>
                    <Box>
                        <NoteForm/>
                    </Box>
                </>
            ),
            dialogAction: (
                <>
                    <Box>
                        <Button variant="contained" color="primary"
                                onClick={this.onClickButton_AddNote.bind(this)}>{title}</Button>
                    </Box>
                </>
            )
        }
    }

    onClickButton_AddNote(event) {
        event.preventDefault();

        Restful.Graphql(Restful.Queries.NoteQuery.MUTATION_ADD_NOTE, {
            title: this.props.tempNoteReducer.title,
            noteBody: this.props.tempNoteReducer.noteBody,
            color: this.props.tempNoteReducer.color,
            noteType: this.props.tempNoteReducer.noteType,
            noteSkip: this.props.sessionStorageReducer.data.noteList.skip,
            noteLimit: this.props.sessionStorageReducer.data.userSettings.noteRecordCountPerPage,
        }, this, (response) => {
            const data = response.data.data.addNote;
            this.props.toastShow(TOAST_SHOW_SUCCESSFUL, "Add note successful...");
            SessionStorage.set(SessionStorage.keys.noteList, data.user.notes, this);
            this.props.dialog(CLOSE_DIALOG);
        }, (errorMessage, isUnhandled) => {
            if (isUnhandled === false) {
                this.props.toastShow(TOAST_SHOW_ERROR, errorMessage);
            }
            else {
                this.props.toastShow(TOAST_SHOW_ERROR, "UnHandled Error: " + errorMessage);
            }
        });
    }

    render() {
        return (
            <>
                <CustomDialog content={this.DialogContent()} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    loadingShow,
    toastShow,
    dialog,
    sessionStorageNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddNoteDialog));
