import React, {Component} from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

class Footer extends Component {
    render() {
        return (
            <>
                <br/>
                <Divider/>
                <Box display="flex" justifyContent="center" pt={3}>
                    <Typography variant="caption">
                        © 2020 Copyright: <Link href="http://www.serkanayaz.com/" target={"_blank"} rel="noopener noreferrer"> SerkanAyaz.com</Link>
                    </Typography>
                </Box>
                <br/>
                <Box display="flex" justifyContent="center" py={0}>
                    <Link href={"https://www.linkedin.com/in/serkanayaz/"} target={"_blank"} rel="noopener noreferrer">
                        <LinkedInIcon color="action" fontSize="large" />
                    </Link>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Link href={"http://www.serkanayaz.com/"} target={"_blank"} rel="noopener noreferrer">
                        <LanguageIcon color="action" fontSize="large" />
                    </Link>
                </Box>
            </>
        );
    }
}

export default Footer;
