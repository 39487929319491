import { createMuiTheme } from '@material-ui/core/styles';
import moment from "moment";
import SessionStorage from "./SessionStorage";

const theme = createMuiTheme();

class Functions {
    static DeepCopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static isNull(obj) {
        return obj === undefined || obj === null;
    }

    static isNullOrEmpty(str) {
        return str === undefined || str === null || str === "";
    }

    static isNaN(number) {
        return Number.isNaN(number);
    }

    static getColor(color) {
        const isDarkMode = SessionStorage.get(SessionStorage.keys.userSettings, {
            isDarkMode: false
        }).isDarkMode;
        if (color === "primary")
            if (isDarkMode)
                return theme.palette.primary.dark;
            else
                return theme.palette.primary.light;
        else if (color === "secondary")
            if (isDarkMode)
                return theme.palette.secondary.dark;
            else
                return theme.palette.secondary.light;
        else if (color === "danger")
            if (isDarkMode)
                return theme.palette.error.dark;
            else
                return theme.palette.error.light;
        else if (color === "warning")
            if (isDarkMode)
                return theme.palette.warning.dark;
            else
                return theme.palette.warning.light;
        else if (color === "info")
            if (isDarkMode)
                return theme.palette.info.dark;
            else
                return theme.palette.info.light;
        else if (color === "success")
            if (isDarkMode)
                return theme.palette.success.dark;
            else
                return theme.palette.success.light;
        else if (color === "white")
            if (isDarkMode)
                return theme.palette.grey.A200;
            else
                return "#ffffff";
        else if (color === "dark")
            if (isDarkMode)
                return theme.palette.grey.A700;
            else
                return theme.palette.grey.A100;

        throw new Error("Color not found! Color: " + color + ", isDarkMode: " + isDarkMode)
    }

    static getFormatedDate(millisecond) {
        if (this.isNull(millisecond)) {
            return null;
        }

        //millisecond = Date.now();
        //const result = moment(parseInt(milliseconds)).format();
        return moment(parseInt(millisecond)).format("DD.MM.YYYY HH:mm:ss");
    }
}

export default Functions;
